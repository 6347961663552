import { useTheme } from "@mui/system"
import React, { useState } from "react"
import { usePageTitle } from "../hooks/TitleHook"
import LayoutItem from "../layoutBuilder/LayoutItem"
import LayoutWrapper from "../layoutBuilder/LayoutWrapper"
import { RetirementProjectsEndpoint } from "./controlTables/RetirementProjectsEndpoint"
import { RetirementsByProjectType } from "./controlTables/RetirementsByProjectTypeEndpoint"
import { RetirementsByCountryEndpoint } from "./controlTables/RetirementsByCountryEndpoint"
import { RetirementsCertificatesEndpoint } from "./controlTables/RetirementsCertificatesEndpoint"
import { GetVehicleEmissionsAcriss } from "./controlTables/VehicleEmissonsByClassEndpoint"
import { DonutChartEndpoints } from "./controlTables/DonutChartEndpoints"
import { CustomerModeEndpoint } from "./controlTables/CustomerModeEndpoint"
import { DashboardKPIEndpoint } from "./controlTables/DashboardKPIEndpoint"
import { SdgEndpoint2023_02_03 } from "./controlTables/SdgEndpoint2023-02-03"
import { EmissionsOverviewEndpoint } from "./controlTables/EmissionsOverviewEndpoint"
import { EMISSIONS_GROUPBY_ENDPOINT, LATEST_API_VERSION } from "../util/Constants"

const GroundControl = () => {
	usePageTitle("BlueHalo - Ground Control")
	const theme = useTheme()

	const layoutItemProps = {
		lg: 6,
		xl: 6,
		minHeight: 220,
		elevation: 0,
		innerStyle: { padding: 0 },
		outerStyle: { padding: 0, margin: theme.spacing(3) },
	}

	const initialState = {
		kpi: false,
		projects: false,
		retirements: false,
		emissions: false,
		sdg: false,
	}

	const [showHideState, setShowHideState] = useState(initialState)

	const showHide = (section) => {
		const value = !showHideState[section]
		setShowHideState((prevState) => ({ ...prevState, [section]: value }))
	}

	return (
		<LayoutWrapper className="p-2-theme">
			<LayoutWrapper>
				<LayoutItem>
					<CustomerModeEndpoint />
				</LayoutItem>
				<LayoutItem>
					<h2>Dashboard KPI endpoint</h2>
					<button onClick={() => showHide("kpi")}>{showHideState.kpi ? "Hide" : "Show"}</button>
					{showHideState.kpi && <DashboardKPIEndpoint />}
				</LayoutItem>
			</LayoutWrapper>
			<LayoutWrapper>
				<LayoutItem>
					<h2>Project endpoints</h2>
					<button onClick={() => showHide("projects")}>{showHideState.projects ? "Hide" : "Show"}</button>
					{showHideState.projects && (
						<LayoutWrapper className="p-2-theme">
							<LayoutItem {...layoutItemProps}>
								<RetirementsByProjectType />
							</LayoutItem>
							<LayoutItem {...layoutItemProps}>
								<RetirementProjectsEndpoint />
							</LayoutItem>
						</LayoutWrapper>
					)}
				</LayoutItem>
				<LayoutItem>
					<h2>Retirement endpoints</h2>
					<button onClick={() => showHide("retirements")}>{showHideState.retirements ? "Hide" : "Show"}</button>
					{showHideState.retirements && (
						<LayoutWrapper className="p-2-theme">
							<LayoutItem {...layoutItemProps}>
								<RetirementsByProjectType />
							</LayoutItem>
							<LayoutItem {...layoutItemProps}>
								<RetirementsByCountryEndpoint />
							</LayoutItem>
							<LayoutItem {...layoutItemProps}>
								<RetirementsCertificatesEndpoint />
							</LayoutItem>
						</LayoutWrapper>
					)}
				</LayoutItem>
				<LayoutItem>
					<h2>Emissions endpoints</h2>
					<button onClick={() => showHide("emissions")}>{showHideState.emissions ? "Hide" : "Show"}</button>
					{showHideState.emissions && (
						<LayoutWrapper className="p-2-theme">
							<LayoutItem {...layoutItemProps}>
								<EmissionsOverviewEndpoint />
							</LayoutItem>
							<LayoutItem {...layoutItemProps}>
								<h3>Air</h3>
								<h4>Group by Travel class</h4>
								<DonutChartEndpoints
									title={"By class"}
									modeOverride={["air"]}
									queryData={{ method: "get", url: EMISSIONS_GROUPBY_ENDPOINT, params: { groupBy: "travelclass", "api-version": LATEST_API_VERSION } }}
								/>
								<h4>Group by Destination</h4>
								<DonutChartEndpoints
									title={"Destinations"}
									modeOverride={["air"]}
									queryData={{ method: "get", url: EMISSIONS_GROUPBY_ENDPOINT, params: { groupBy: "destination", "api-version": LATEST_API_VERSION } }}
								/>
							</LayoutItem>
							<LayoutItem {...layoutItemProps}>
								<h3>Road</h3>
								<GetVehicleEmissionsAcriss />
								<h4>Group by Destination</h4>
								<DonutChartEndpoints
									title={"Destinations"}
									modeOverride={["road"]}
									queryData={{ method: "get", url: EMISSIONS_GROUPBY_ENDPOINT, params: { groupBy: "destination", "api-version": LATEST_API_VERSION } }}
								/>
							</LayoutItem>
							<LayoutItem {...layoutItemProps}>
								<h3>Accommodation</h3>
								<h4>Group by Star rating</h4>
								<DonutChartEndpoints
									title={"By star rating"}
									modeOverride={["accommodation"]}
									queryData={{ method: "get", url: EMISSIONS_GROUPBY_ENDPOINT, params: { groupBy: "starrating", "api-version": LATEST_API_VERSION } }}
								/>
								<h4>Group by Country</h4>
								<DonutChartEndpoints
									title={"By country"}
									modeOverride={["accommodation"]}
									queryData={{ method: "get", url: EMISSIONS_GROUPBY_ENDPOINT, params: { groupBy: "country", "api-version": LATEST_API_VERSION } }}
								/>
							</LayoutItem>
						</LayoutWrapper>
					)}
				</LayoutItem>
				<LayoutItem>
					<h2>SDG endpoints</h2>
					<button onClick={() => showHide("sdg")}>{showHideState.sdg ? "Hide" : "Show"}</button>
					{showHideState.sdg && <SdgEndpoint2023_02_03 className="MuiGrid-grid-md-12" />}
				</LayoutItem>
			</LayoutWrapper>
		</LayoutWrapper>
	)
}

export default GroundControl
