import { Alert, Box, Button, Table, TableBody, Typography, TableRow, TableCell, MenuItem } from "@mui/material"
import { usePageTitle } from "../../hooks/TitleHook"
import TableWrapper from "../../components/Table/TableWrapper/TableWrapper"
import { EMISSIONS_LOG_ENDPOINT, LATEST_API_VERSION } from "../../util/Constants"
import LoadingSpinner from "../../components/Loading/LoadingSpinner"
import TableHeader from "../../components/Table/TableHeader/TableHeader"
import RowWithChild from "../../components/Table/TableRows/RowWithChild/RowWithChild"
import { emissionsLogResponseToTableData } from "../../lib/convertions/EmissionsLogDataSet"
import TablePagination from "../../components/Table/TablePagination/TablePagination"
import { useState } from "react"
import styles from "../index.module.scss"
import { setQueryParameters } from "../sub-components/SetQueryParameters"
import { objectOfBoolsToCSV } from "../../util/util"
import ActivityFilterButton from "../../components/Filter/Wireups/ActivityFilterButton"
import DatePickerFilterButton from "../../components/Filter/Wireups/DatePickerFilterButton"
import { ExportModal } from "../../components/ExportModal"
import { ExportCostCentresModal } from "../../components/ExportCostCentresModal"
import DownloadIcon  from "@mui/icons-material/Download"
import { useFlags } from "launchdarkly-react-client-sdk"

const HEADER_COLUMNS = [
	{ title: "Reference ID", cellKey: "TransactionReferenceNumber", canSort: true, sortKey: "transactionReferenceNumber" },
	{ title: "Mode", canSort: false },
	{ title: "Transaction Date", cellKey: "Date", canSort: true, sortKey: "date" },
	{ title: "Activity Date", cellKey: "ActivityDate", canSort: true, sortKey: "activityDate" },
	{ title: "Emissions (tCO2e)", cellKey: "Emissions", canSort: true, sortKey: "emission", headerAlign: "right", cellAlign: "right" },
	{ title: "Details", canSort: false },
]

const EmissionsLogTable = ({ rows }) => {
	return (
		<TableBody>
			{rows.map((row, i) => (
				<RowWithChild key={i} hashKey={i} value={row} childComponent={row.Metadata ?? null} columns={HEADER_COLUMNS} />
			))}
		</TableBody>
	)
}

const FullWidthRowWrapper = ({ children }) => {
	return (
		<TableRow>
			<TableCell className="border-0 font-regular" colSpan={"100%"}>
				{children}
			</TableCell>
		</TableRow>
	)
}

const dateFormat = "YYYY-MM-DD"

const EmissionsLog = () => {
	usePageTitle("BlueHalo - Emissions Log")
	const [pageNumber, updatePageNumber] = useState(0)
	const [pageSize, updatePageSize] = useState(25)
	const [openModal, setOpenModal] = useState(false)
	const [openCCModal, setOpenCCModal] = useState(false)
	const { bhCostCentres } = useFlags()
	const paramsFromAppState = setQueryParameters()

	return (
		<Box width="100%" height="100%">
			<Box>
				<TableWrapper
					queryData={{
						url: EMISSIONS_LOG_ENDPOINT,
						method: "get",
						params: {
							"api-version": LATEST_API_VERSION,
							pageSize,
							pageNumber,
							partners: paramsFromAppState.partners,
						},
					}}
					dataTransformFunction={(data, frontendFilters) => emissionsLogResponseToTableData(data, frontendFilters, pageSize)}
				>
					{({ transformedData, updateFilterValue, loaded, error, rawData }) => {
						const [queryParams, setQueryParams] = useState({
							dateFrom: null,
							dateTo: null,
							activityDateFrom: null,
							activityDateTo: null,
						})
						const { logData, totalPages } = transformedData

						const updateQuerys = (val) => {
							setQueryParams({ ...queryParams, ...val })
							updateFilterValue({ ...queryParams, ...val })
						}

						return (
							<>
								<Box className={"p-lr-2-theme display-flex align-center wrap justify-between position-relative"} minHeight="50px">
									<Box>
										<Typography variant="h3" textAlign="start" className="txt-main m-b-0 txt-col-black">
											Emissions Log
										</Typography>
									</Box>
									<Box className="display-flex h-100-percent">
										<DatePickerFilterButton
											className={"m-l-2-theme"}
											label={"Transaction Date"}
											value={queryParams}
											onChange={updateQuerys}
											DatePickerProps={{ fromKey: "dateFrom", toKey: "dateTo" }}
										/>
										<DatePickerFilterButton
											className={"m-l-2-theme"}
											label={"Activity Date"}
											value={queryParams}
											onChange={updateQuerys}
											DatePickerProps={{ fromKey: "activityDateFrom", toKey: "activityDateTo" }}
										/>
										<ActivityFilterButton className={"m-l-2-theme"} onChange={(val) => updateQuerys({ ...queryParams, modes: objectOfBoolsToCSV(val) })} />
										
										<Button onClick={() => setOpenModal(true)} className="button-primary m-l-2-theme"><DownloadIcon /> Export log</Button>
										{bhCostCentres && <Button onClick={() => setOpenCCModal(true)} className="button-primary m-l-2-theme"><DownloadIcon /> Cost Centres</Button>}
									</Box>
								</Box>
								<Table id={styles["emissions-table"]}>
									<TableHeader
										onSortUpdate={(orderBy, order) => {
											updateQuerys({ ...queryParams, orderBy, order })
										}}
										columns={HEADER_COLUMNS}
										defaultSortColumn={"date"}
										colSpan={"100%"}
										showBorder
										disableSort={false}
									/>
									{error ? (
										<FullWidthRowWrapper>
											<Box width={"100%"} overflow="auto">
												<Alert severity="error" data-testid={"error-component"}>
													{error}
												</Alert>
											</Box>
										</FullWidthRowWrapper>
									) : (
										<>
											{loaded ? (
												<>{logData && logData.length ? <EmissionsLogTable rows={logData} /> : <FullWidthRowWrapper>No Data Available</FullWidthRowWrapper>}</>
											) : (
												<FullWidthRowWrapper>
													<Box className="flex-column position-relative" minHeight={100}>
														<LoadingSpinner />
													</Box>
												</FullWidthRowWrapper>
											)}
										</>
									)}
									{loaded && logData && logData.length ? (
										<FullWidthRowWrapper>
											<TablePagination currentPage={pageNumber + 1} totalPages={totalPages} onPageChange={(e, page) => updatePageNumber(page - 1)} />
										</FullWidthRowWrapper>
									) : (
										<></>
									)}
								</Table>
								<ExportModal open={openModal} handleClose={() => setOpenModal(false)} rawData={rawData} queryParams={queryParams} />
								{bhCostCentres &&
									<ExportCostCentresModal open={openCCModal} handleClose={() => setOpenCCModal(false)} rawData={rawData} queryParams={queryParams} paramsFromAppState={paramsFromAppState} />
								}
							</>
						)
					}}
				</TableWrapper>
			</Box>	
		</Box>
	)
}

export default EmissionsLog
