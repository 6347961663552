import { useModeList } from "../../hooks/ModeHook"
import { usePageTitle } from "../../hooks/TitleHook"
import LayoutItem from "../../layoutBuilder/LayoutItem"
import LayoutWrapper from "../../layoutBuilder/LayoutWrapper"
import AllEmissionsByMode from "../../components/Charts/Wireups/AllEmissionsByMode"
import GraphTitle from "../../components/ChartsVisX/shared/GraphTitle"
import { useTheme } from "@mui/system"
import AllEmissionsByModeDonut from "../../components/Charts/Wireups/AllEmissionsByModeDonut"
import GroupByDonut from "../../components/Charts/Wireups/GroupByDonut"

const globalItemStyle = {
	lg: 6,
	xl: 6,
	minHeight: 220,
	elevation: 0,
	innerStyle: { padding: 0 },
}

const TravelView = () => {
	const theme = useTheme()
	const outerStyle = { marginTop: theme.spacing(3) }

	return (
		<LayoutItem>
			<GraphTitle title="Emissions - Travel breakdown" subTitle={"Data source: BlueHalo"} />
			<LayoutWrapper style={{ padding: theme.spacing(2, 2, 0, 2), marginBottom: 0 }}>
				<LayoutItem {...globalItemStyle} outerStyle={{ ...outerStyle, padding: theme.spacing(0, 2, 0, 0) }}>
					<AllEmissionsByModeDonut />
				</LayoutItem>
			</LayoutWrapper>
		</LayoutItem>
	)
}

const AirRetirementsView = () => {
	const theme = useTheme()
	const outerStyle = { marginTop: theme.spacing(3) }

	return (
		<LayoutItem>
			<GraphTitle title="Emissions - Flight breakdown" subTitle={"Data source: BlueHalo"} />
			<LayoutWrapper style={{ padding: theme.spacing(2, 2, 0, 2), marginBottom: 0 }}>
				<LayoutItem {...globalItemStyle} outerStyle={{ ...outerStyle, padding: theme.spacing(0, 2, 0, 0) }}>
					<GroupByDonut title={"By class"} groupBy="travelclass" modeOverride={["air"]} />
				</LayoutItem>
			</LayoutWrapper>
		</LayoutItem>
	)
}

const AccommodationView = () => {
	const theme = useTheme()
	const outerStyle = { marginTop: theme.spacing(3) }

	return (
		<LayoutItem>
			<GraphTitle title="Emissions - Accommodation breakdown" subTitle={"Data source: BlueHalo"} />
			<LayoutWrapper style={{ padding: theme.spacing(2, 2, 0, 2), marginBottom: 0 }}>
				<LayoutItem {...globalItemStyle} outerStyle={{ ...outerStyle, padding: theme.spacing(0, 2, 0, 0) }}>
					<GroupByDonut title={"By star rating"} groupBy="starrating" modeOverride={["accommodation"]} />
				</LayoutItem>
				<LayoutItem {...globalItemStyle} outerStyle={{ ...outerStyle, padding: theme.spacing(0, 0, 0, 2) }}>
					<GroupByDonut title={"By country"} groupBy="country" modeOverride={["accommodation"]} DonutProps={{ DonutLegendProps: { keyClasses: "txt-transform-uppercase" } }} />
				</LayoutItem>
			</LayoutWrapper>
		</LayoutItem>
	)
}

const RoadView = () => {
	const theme = useTheme()
	const outerStyle = { marginTop: theme.spacing(3) }

	return (
		<LayoutItem>
			<GraphTitle title="Emissions - Car Rental breakdown" subTitle={"Data source: BlueHalo"} />
			<LayoutWrapper style={{ padding: theme.spacing(2, 2, 0, 2), marginBottom: 0 }}>
				<LayoutItem {...globalItemStyle} outerStyle={{ ...outerStyle, padding: theme.spacing(0, 2, 0, 0) }}>
					<GroupByDonut title={"By days"} groupBy="rentalDays" modeOverride={["road"]} />
				</LayoutItem>
				{/*  Is this only for Serko?  Do we need a condition here? */}
				<LayoutItem {...globalItemStyle} outerStyle={{ ...outerStyle, padding: theme.spacing(0, 0, 0, 2) }}>
					<GroupByDonut title={"By type"} groupBy="acrisCode" modeOverride={["road"]} DonutProps={{ DonutLegendProps: { keyClasses: "txt-transform-uppercase" } }} />
				</LayoutItem>
			</LayoutWrapper>
		</LayoutItem>
	)
}

const Emissions = () => {
	usePageTitle("BlueHalo - Emissions")
	const modes = useModeList()

	if (!modes) return null

	return (
		<LayoutWrapper>
			<LayoutItem minHeight={300}>
				<AllEmissionsByMode />
			</LayoutItem>
			{modes.length > 1 && <TravelView />}
			{modes.map((mode) => {
				switch (mode) {
					case "air":
						return <AirRetirementsView key={"view-1"} />
					case "accommodation":
						return <AccommodationView key={"view-2"} />
					case "road":
						return <RoadView key={"view-3"} />
					default:
						return <></>
				}
			})}
		</LayoutWrapper>
	)
}

export default Emissions
